@media (min-width: 992px) {
  .brand {
    background-color: #ffffff;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .brand .btn i {
    color: #b4b8ce;
  }
  .brand .btn .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #b4b8ce;
  }
  .brand .btn .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  .brand .btn:hover .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3699FF;
  }
  .brand .btn:hover .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  .brand .btn:hover i {
    color: #3699FF;
  }
}
@media (max-width: 991.98px) {
  .header-mobile {
    background-color: #ffffff;
    -webkit-box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.1);
  }
  .header-mobile .burger-icon span {
    background-color: #b4b8ce;
  }
  .header-mobile .burger-icon span::before, .header-mobile .burger-icon span::after {
    background-color: #b4b8ce;
  }
  .header-mobile .burger-icon:hover span {
    background-color: #3699FF;
  }
  .header-mobile .burger-icon:hover span::before, .header-mobile .burger-icon:hover span::after {
    background-color: #3699FF;
  }
  .header-mobile .burger-icon-active span {
    background-color: #3699FF;
  }
  .header-mobile .burger-icon-active span::before, .header-mobile .burger-icon-active span::after {
    background-color: #3699FF;
  }
  .header-mobile .btn i {
    color: #b4b8ce;
  }
  .header-mobile .btn .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #b4b8ce;
  }
  .header-mobile .btn .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  .header-mobile .btn:hover .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3699FF;
  }
  .header-mobile .btn:hover .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  .header-mobile .btn:hover i {
    color: #3699FF;
  }
}