html {
    height: 100%;
}

.navbar {
    padding: 15px 10px;
    background: red;
    border: 1px solid #e5e5e5;
    border-radius: 12px;
    margin-bottom: 10px;
    height: 100%;
    /* box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1); */
}

.navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
}

.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #0ba3ba;
    margin: 40px 0;
}

i,
span {
    display: inline-block;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
    background-color: #F2F5FA;
}

#sidebar {
    min-width: 220px;
    max-width: 220px;
    background: white;
    color: #989fa7;
    transition: all 0.3s;
    /* border-right: 1px solid #e5e5e5; */
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    /*box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);*/
    border-radius: 0px 30px 30px 0px;
}


/* Aqui edite pal HOVER 90% */
#sidebar ul li a {
    text-align: left;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}


#sidebar .sidebar-logo {
    padding: 10px 65px;
}

#sidebar .sidebar-header {
    height: 165px;
    padding-top: 20px;

}

#sidebar .sidebar-header img {
    height: 120px;

}

#sidebar.active .sidebar-header {
    display: none;

}

#sidebar .sidebar-header strong {
    display: none;
    font-size: 1.8em;
}

/* Color ACTIVO sidebar texto*/
#sidebar a.active {
    color: #5b5e6e;
    font-weight: 500;
    background: #F2F5FA;
    border-radius: 30px 30px 30px 30px;
    /* margin: 0px 15px; */
    padding: 12px 40px;

    
}

/* Color ACTIVO sidebar icon */
#sidebar a.active i {
    color: #5b5e6e;
}



#sidebar ul.components {
    padding: 0px;
    /* border-bottom: 1px solid #e5e5e5; */

}

/* Sidebar text color */
#sidebar ul li a {
    padding: 12px 40px;
    font-size: 1.08rem;
    display: block;
    color: #3A3A3A;
    /* border-bottom: 1px solid; */
    color: #7E8299;
    

}

/* Sidebar text HOVER color */
#sidebar ul li a:hover {
    color: #5b5e6e;
    background: #F2F5FA;
    border-radius: 30px 30px 30px 30px;
    /* margin: 0px 15px; */
    padding: 12px 40px;
}

/* Sidebar icon color */
#sidebar ul li a i {
    margin-right: 10px;
    color: #7E8299;

}


a[data-toggle="collapse"] {
    position: relative;
}

#sidebar .dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}

ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    /*background: #000;*/
    background: green;
}

ul.CTAs {
    padding: 20px;
}

ul.CTAs a {
    text-align: center;
    font-size: 0.9em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
}

#sidebar ul li a.download {
    padding: 6px;
}

a.download {
    background: #fff;
    color: green;
}

a.article,
a.article:hover {
    background: #0062cc !important;
    color: #fff !important;
}


/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content {
    width: 100%;
    padding-left: 10px;
    min-height: 100vh;
    transition: all 0.3s;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
    #sidebar {
        min-width: 50px;
        max-width: 50px;
        text-align: center;
        margin-left: -50px !important;
        font-size: small;
    }

    #sidebar .sidebar-logo {
        padding: 8px 50px;
    }

    #sidebar .dropdown-toggle::after {
        top: auto;
        bottom: 10px;
        right: 50%;
        -webkit-transform: translateX(50%);
        -ms-transform: translateX(50%);
        transform: translateX(50%);
    }

    #sidebar .sidebar-header h3,
    #sidebar .CTAs {
        display: none;
    }

    #sidebar .sidebar-header {
        height: auto;
    }

    #sidebar .sidebar-header strong {
        padding: 20px 0px;
        display: none;
    }

    #sidebar ul li a {
        padding: 20px 10px;
    }

    #sidebar ul li a span {
        font-size: 0.7em;
    }


    #sidebar ul li a i {
        margin-right: 0;
        display: block;
    }

    #sidebar ul ul a {
        padding: 10px !important;
    }

    #sidebar ul li a i {
        font-size: 1.3em;
    }

    #sidebar {
        margin-left: 0;
    }

    #sidebarCollapse span {
        display: none;
    }
}
