@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');


body {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
}

p {
    margin: 0;
}

#content {
    background: #F2F5FA;
    /* border-bottom-left-radius: 20px;
    border-top-left-radius: 20px; */
}

.seat-select:hover {
    background-color: rgba(244, 162, 97, 30);
    cursor: pointer;
}

 .modal-content  {
    border-radius: 20px;
    padding: 10px 25px;
}

 .modal-header {
    font-size: 1.5rem;
    border-bottom: none;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
}

.modal-body {

}

 .modal-footer {
    border-top: none;
}

 .clickable:hover {
     cursor: pointer;
 }

/*.react-grid-item {*/
/*    background: #0ba3ba;*/
/*    border-radius: 100%;*/
/*    cursor: pointer;*/
/*}*/

.opacity30 {
    opacity: 0.3;
}


.karpseudo:hover {
    background-color: rgba(219, 219, 219, 0.137);
}

.kaglass {
    background: rgba( 255, 255, 255, 0.8 );
    box-shadow: 0 8px 32px 0 rgba(155, 155, 155, 0.37);
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
}

.kaheight {
    background-image: url("../img/login1.png"); /* The image used */
    background-color: #cccccc; /* Used if the image is unavailable */
    height: 100%; /* You must set a specified height */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
}

.kalogin { 
    background: url("../img/login2.png") no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
      /* Set rules to fill background */
    min-height: 100%;
    min-width: 100%;
	
    /* Set up proportionate scaling */
    width: 100%;
    height: auto;
	
    /* Set up positioning */
    position: fixed;
    top: 0;
    left: 0;
  }

  .dashcard {

    min-height: 30%;
  }

  .kanotifimage {
    content:url("../img/notificaciones1024x1024.png");
  }

  .webimage {
    content:url("../img/website.png");
  }

  .appimage {
    content:url("../img/bookapp.png");
  }

  .notifications-image {
    content:url("../img/notificaciones.png");
  }

  .notif-image {
    content:url("../img/appiconcb.png");
  }
