.content-sidebar ul {
    list-style-type: none;
    font-weight: 600;
    padding-left: 0;
}

.content-sidebar ul li {
    cursor: pointer;
    padding: 30px 80px;
    border-bottom: 1px solid rgba(1,1,1,0.2);
}