.card-hover:hover{
    transform: scale(1.01);
    box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}

.my-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0.42rem;
    background-color: rgba(255, 255, 255, 0.452);
}



/* DATE PICKER */
/* DATE PICKER */
/* DATE PICKER */
/* DATE PICKER */
/* DATE PICKER */


/* general */
.react-datepicker {
    font-family: "Helvetica Neue", helvetica, arial, sans-serif;
    font-size: 1rem;
    background-color: #fff;
    color: #000;
    border: 1px solid #aeaeae6c !important;
    border-radius: 25px !important;
    display: inline-block;
    position: relative;
  }
  
  /* mes */
  .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
    margin-top: 0;
    color: #000000;
    font-weight: bold;
    font-size: 1.2rem;
  }
  
  /* dias */
  .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    display: inline-block;
    width: 2.2rem;
    line-height: 2.3rem;
    text-align: center;
    margin: 0.166rem;
    font-size: 14px;
    font-weight: 500;
  }
  
  
  /* dia seleccionado */
  .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    border-radius: 3rem;
    background-color: #3699FF;
    color: #fff;
  }
  
  /* nombre día lun mar mie jue vie */
  .react-datepicker__day-name {
    color: #b6b6b6;
    display: inline-block;
    width: 2.1rem;
    line-height: 2rem;
    text-align: center;
    margin: 0.166rem;
    font-size: 14px;
    font-weight: 500;
  }
  
  /* FIN DATE PICKER */
  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
    height: 45px;
    padding: 5px 10px;
    white-space: nowrap;
  }
  
  /* HEADER */
  .react-datepicker__header {
    text-align: center;
    background-color: #ffffff;
    border-bottom: 1px solid #aeaeae;
    /* border-top-left-radius: 25px !important; */
    padding: 12px 0;
    position: relative;
    border-radius: 25px 25px 0px 0px;
  }
  
  /* arrows */
  .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
    border-color: #ccc;
    border-style: solid;
    border-width: 2px 2px 0 0;
    content: "";
    display: block;
    height: 9px;
    position: absolute;
    top: 15px;
    width: 9px;
    
  }
  
  /* border radius */
  .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    border-top-right-radius: 25px;
  }

  /* calendar some default day */
  .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
    border-radius: 3rem;
    background-color: #d7e3f0;
    color: #fff;
}

/* calendar first day selected of range */
.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover {
    border-radius: 3rem;
    background-color: #3699FF;
    color: #fff;
}

/* dates hovered waiting for end date select */
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
    background-color: #369bff8e;
}

/* TIMEPICKER */

/* time Container height */
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
    height: 35px;
    padding: 1px 10px;
    white-space: nowrap;
}

/* time selected color */
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: #3699FF;
    color: white;
    font-weight: bold;
}

/* time full conteiner height */
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    list-style: none;
    margin: 0;
    height: calc(210px + (1.7rem / 2));
    overflow-y: scroll;
    padding-right: 0;
    padding-left: 0;
    width: 100%;
    box-sizing: content-box;
}

  
/* FIN DATEPICKER */
/* FIN DATEPICKER */
/* FIN DATEPICKER */
/* FIN DATEPICKER */


/* NEW TABS for TABNAVIGATOR2*/
/* tabs activo */
.nav.nav-tabs.nav-tabs-line2 .nav-link:hover:not(.disabled), .nav.nav-tabs.nav-tabs-line2 .nav-link.active, .nav.nav-tabs.nav-tabs-line2 .show > .nav-link {
  background-color: #E1F0FF;
  border: 0;
  border-radius: 50px;
  border-bottom: 1px solid #46566500;
  margin-bottom: 6px !important;
  /* transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease; */
}

/* tabs inactivo */
.nav.nav-tabs.nav-tabs-line2 .nav-link {
  border: 0;
  background-color: #7e82991c;
  border-radius: 50px;

  border-bottom: 1px solid transparent;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  padding: 0.85rem 0;
  margin: 2px;
  
}
/* FIN TABS 2 */